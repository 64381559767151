.md-nav__link {
  padding: 0 0.6rem;
  color: var(--dark);
  line-height: 32px;
}

.md-nav__link::after {
  font-size: 16px;
  vertical-align: -0.25em;
}

.md-nav__toggle:checked + .md-nav__link,
.md-nav__link--active,
.md-nav__link:hover {
  color: var(--dark) !important;
  background-color: var(--light-blue) !important;
  border-radius: 8px;
  transition: background-color 300ms ease;
}

.md-nav__link--active {
  color: var(--blue) !important;
  text-shadow: 0px 0px 1px var(--blue), 0px 0px 1px var(--blue), 0px 0px 1px var(--blue);
}

.md-sidebar--primary {
  background-color: #FFFFFF;
}

.md-sidebar--secondary .md-nav__title {
  margin-bottom: 0.4rem;
  padding: 0.8rem 0.4rem 0.8rem;
  font-size: 12px;
  text-transform: uppercase;
}

.md-sidebar--secondary .md-sidebar__scrollwrap {
  height: initial !important;
}

.md-sidebar--secondary .md-nav__list {
  padding: 0 0rem 0rem 1.2rem;
}

.md-sidebar--secondary .md-sidebar__scrollwrap .md-nav__link {
  font-weight: 300;
}

.md-sidebar--secondary
.md-sidebar__scrollwrap
.md-nav__link[data-md-state="blur"]:not(.md-nav__link--active) {
  color: #666666;
}

.md-sidebar--secondary .md-sidebar__scrollwrap .md-nav__link:hover {
  color: var(--blue) !important;
  text-shadow: 0px 0px 1px var(--blue);
}

.md-sidebar--secondary .md-nav__link {
  margin-top: 0.225em;
  padding: 0.1rem 0.2rem;
}

.md-nav[data-md-level="0"] > .md-nav__list > .md-nav__item {
  padding: 0 !important;
}

.md-sidebar--secondary li {
  list-style-type: disc;
}

.md-search {
  display: flex;
  justify-content: center;
}

.md-search__inner {
  float: inherit;
}

.md-search__input {
  margin-bottom: 10px;
  background-color: inherit;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

.md-search__input::placeholder {
  color: rgba(0, 0, 0, 0.07);
}

.md-search__icon {
  color: #000000 !important;
}

.md-header-nav__source {
  margin-left: 0;
}

/* stylelint-disable-next-line selector-class-pattern */
.repo_url {
  height: initial !important;
  margin-bottom: 0.4rem;
}

@include break-to-device(screen) {
  .md-search__inner {
    top: 64px;
    right: 0;
    margin-right: 0; 
    margin-left: 0;
  }
}

// hamburger trigger for side panel menu
.header-container__mobile {
  display: none;
}

label.md-icon.md-icon--menu.md-header-nav__button{
  position: fixed;
  top: 65px;
  z-index: 4;
  width: fit-content;
}

@media screen and (max-width: 76.1875em) {
  .header-container__mobile {
    position: fixed;
    top: 50px;
    display: block;
    width: 100%;
    height: 65px;
    background-color: inherit;
  }
}

// header search form positioning
@media only screen and (min-width: 76.25em) {
  [data-md-toggle=search]:checked~.md-header .md-search__inner {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 60em) and (max-width: 76.1875em) {
  [data-md-toggle=search]:checked~.md-header .md-search__inner {
    margin-top: 0.4rem;
  }
}

// sidebar on smaller screen
@media screen and (max-width: 76.1875em) {
  .md-sidebar.md-sidebar--primary {
    margin-top: 50px;

    nav.product-switcher {
      top: 60px;
      z-index: 2;
      width: 100%;
      text-align: center;

      div.menu-item-wrapper.menu-item-wrapper--dropdown {
        margin-left: 0;
      }
    }

    .md-sidebar__scrollwrap {
      top: 50px;
  
      .md-nav--primary {
        // MKdocs header and link
        label.md-nav__title[for=__drawer] {
          a {
            display: none !important;
          }

          height: 50px;
          color: transparent;
          background-color: transparent;
        }

        label.md-nav__title {
          padding-top: 3.1rem;
          background-color: unset;

          span.md-nav__icon.md-icon {
            display: none;
          }
        }
    
        .md-nav__source {
          display: none;
        }
      }
    }
  }

  // to trigger the "real" search form and and result container
  .md-container {
    .md-search__inner {
      top: 10px;
      left: 175px;
      height: fit-content;
      opacity: 1;
      pointer-events: all;
     
      .md-search__output {
        display: none;
      }

      .md-search__form {
        .md-search__input {
          display: none;
        }
        .md-search__icon[for=__search] {
          top: 0.9rem;
          left: 0;
          svg:first-child {
            display: block;
          }
  
          svg:nth-of-type(2) {
            display: none;
          }
        }
      }
    }
  }

  .md-header {
    .md-search__inner {
      position: fixed;
      top: 64px;
      left: 100%;
      z-index: 2;
      width: calc(100% - 50px);
      transform: translateX(50%);
      opacity: 1;
      transition: right 0ms 300ms, left 0ms 300ms, transform 150ms 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms 150ms;
      pointer-events: none;
    }
  }

  [data-md-toggle=search]:checked ~ .md-container {
    .md-search__inner {
      opacity: 0;
      pointer-events: none;
    }
  }

  // don't allow search when side panel is open, to simplify the flow
  [data-md-toggle=drawer]:checked ~ .md-container .md-search__inner {
    opacity: 0;
  }

  [data-md-toggle=search]:checked ~ .md-header .md-search__inner {
    left: 50px;
    transform: translateX(0);
    opacity: 1;
    transition: right 0ms 0ms, left 0ms 0ms, transform 150ms 150ms cubic-bezier(0.1, 0.7, 0.1, 1), opacity 150ms 150ms;
    pointer-events: initial;
  }

  [dir=rtl] [data-md-toggle=search]:checked ~ .md-header .md-search__inner {
    right: 0;
    left: initial;
  }
}

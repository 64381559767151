.product-switcher {
  height: initial !important;
  padding-bottom: 20px;
  color: #06102A;
  font-size: 10px;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  img.gopher {
    margin: 0 2px 0 16px;
  }

  .menu-item-wrapper {
    display: inline-block;
  }

  .menu-item {
    color: #06102A;
    font-weight: 500;
    font-size: 1.45em;
    line-height: 1em;
    transition: all 50ms;
  }

  .menu-item:hover {
    color: #8A959E;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .menu-item--with-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > img {
      margin-right: 10px;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .menu-item--with-icon .title {
    margin-right: 3px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .menu-item--with-icon .icon {
    width: 20px;
    height: 20px;
    transition: all 100ms;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .menu-item--with-icon .icon svg {
    width: 100%;
    height: 100%;
    stroke-width: 2.5 !important;
  }

  .menu-item-wrapper--dropdown {
    position: relative;
  }

  .menu-item-wrapper--dropdown:hover .nav-dropdown-menu {
    display: block;
  }

  .nav-dropdown-menu {
    position: absolute;
    z-index: 500;
    display: none;
    background: transparent;
  }

  .nav-dropdown-menu-wrapper {
    width: auto;
    height: auto;
    margin: 8px 0;
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 12px 40px 0 rgba(1, 10, 32, 0.24);
  }

  .nav-dropdown-menu--products {
    width: 450px;

    .nav-dropdown-menu-wrapper {
      padding: 20px;
    }
  }

  .nav-dropdown-menu--products .dm-header {
    margin-bottom: 20px;
    color: #505769;
    font-weight: 500;
    font-size: 1.1em;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3.67px;
    text-transform: uppercase;
  }
  .nav-dropdown-menu--products .dm-item {
    position: relative;
    width: 100%;
    margin: 0 0 24px;
    color: #06102A;
    border: none;
    transition: all 100ms;
  }
  .nav-dropdown-menu--products .dm-item:last-child {
    margin-bottom: 0;
  }
  .nav-dropdown-menu--products .dm-item .dmi-image {
    position: absolute;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 72px;
    padding: 20px;
    border-radius: 4px;

    & > img {
      width: 48px;
    }
  }

  .nav-dropdown-menu--products .dm-item .dmi-details {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    padding: 8px 0 10px 125px;
    color: #06102A;
    background: transparent;
  }
  .nav-dropdown-menu--products .dm-item .dmi-details:hover {
    color: #1E54D5;
  }
  .nav-dropdown-menu--products .dm-item .dmi-title {
    margin: 0 0 2px;
    font-weight: 500;
    font-size: 1.6em;
  }
  .nav-dropdown-menu--products .dm-item .dmi-description {
    font-size: 1.4em;
    line-height: 1.6em;
    opacity: 0.7;
  }

  .dmi-image {
    &.proxy {
      background-color: #24A1CC;
    }

    &.mesh {
      background-color: #9D0FB0;
    }

    &.enterprise {
      background-color: #0060E0;
    }

    &.hub {
      background-color: #7F8C2B;
    }
  }
}

@media screen and (max-width: 76.1875em) {
  .product-switcher {
    position: inherit;
  }
}
